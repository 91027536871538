.frequency-card {
  .per-month-label {
    color: #000;
    font-size: 12px;
    font-style: normal;
    font-weight: 325;
    line-height: normal;
  }
  @media (max-width: 768px) {
    .per-month-label {
      font-size: 10px;
    }
  }
}
