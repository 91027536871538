#title-update {
  @apply text-left my-10;

  h4 {
    @apply font-bold text-3xl mb-2 leading-6;
  }

  p {
    @apply text-[14px] tracking-wide;
    span {
      @apply text-link text-[14px] font-medium hover:text-link-active cursor-pointer hover:font-medium;
    }
  }
  .one-time {
    line-height: 22px;
    @apply text-[14px] lg:max-w-[430px] tracking-wider;
  }
}
