@import 'tailwindcss/base';

@import 'tailwindcss/components';

@import 'tailwindcss/utilities';

// LAYOUTS
@import 'stylesheets/layout/questionnaire.scss';
@import 'stylesheets/layout/checkout.scss';

@import 'stylesheets/vendors/font-awesome.scss';

// BASE
@import 'stylesheets/base/typography.scss';

// COMPONENTS
@import 'stylesheets/components/store/summary/title.scss';
@import 'stylesheets/components/store/summary/selection.scss';
@import 'stylesheets/components/store/summary/financial.scss';
@import 'stylesheets/components/store/summary/promo.scss';
@import 'stylesheets/components/store/summary/subscription_tabs.scss';
@import 'stylesheets/components/store/questionnaire/progress_bar.scss';

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Montserrat-SemiBold.otf') format('OpenType');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'gotham-light';
  src: url('./fonts/gotham_light.otf') format('opentype');
}

@font-face {
  font-family: 'gotham-bold';
  src: url('./fonts/gotham_bold.otf') format('OpenType');
}

@font-face {
  font-family: 'gotham-medium';
  src: url('./fonts/gotham_medium.otf') format('OpenType');
}

// Gotham
@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Gotham/Gotham-Book.otf') format('OpenType');
}

@font-face {
  font-family: 'Mundial';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/MundialBold.otf') format('OpenType');
}

@font-face {
  font-family: 'Mundial';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/MundialBoldItalic.otf') format('OpenType');
}

@font-face {
  font-family: 'Mundial';
  font-style: italic;
  font-weight: 700;
  src: url('./fonts/MundialBold.otf') format('OpenType');
}

@font-face {
  font-family: 'Mundial';
  font-style: italic;
  font-weight: 400;
  src: url('./fonts/MundialItalic.otf') format('OpenType');
}

@font-face {
  font-family: 'Mundial';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/MundialLight.otf') format('OpenType');
}

@font-face {
  font-family: 'Mundial';
  font-style: italic;
  font-weight: 300;
  src: url('./fonts/MundialLightItalic.otf') format('OpenType');
}

@font-face {
  font-family: 'Mundial';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/MundialRegular.otf') format('OpenType');
}

@font-face {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 700;
  src: url('./fonts/Termina-Bold.otf') format('OpenType');
}

@font-face {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 600;
  src: url('./fonts/Termina-Demi.otf') format('OpenType');
}
@font-face {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 800;
  src: url('./fonts/Termina-Heavy.otf') format('OpenType');
}

@font-face {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/Termina-Light.otf') format('OpenType');
}

@font-face {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 500;
  src: url('./fonts/Termina-Medium.otf') format('OpenType');
}

@font-face {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Termina-Regular.otf') format('OpenType');
}

@font-face {
  font-family: 'Termina';
  font-style: normal;
  font-weight: 200;
  src: url('./fonts/Termina-Thin.otf') format('OpenType');
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-BoldOblique.otf') format('opentype');
  font-weight: bold;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-DemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-DemiBoldOblique.otf') format('opentype');
  font-weight: 600;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-ExtraBoldOblique.otf') format('opentype');
  font-weight: 800;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-Heavy.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-HeavyOblique.otf') format('opentype');
  font-weight: 900;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-LightOblique.otf') format('opentype');
  font-weight: 300;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-MediumOblique.otf') format('opentype');
  font-weight: 500;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-RegularOblique.otf') format('opentype');
  font-weight: normal;
  font-style: oblique;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-Thin.otf') format('opentype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'VanguardCF';
  src: url('./fonts/VanguardCF-ThinOblique.otf') format('opentype');
  font-weight: 100;
  font-style: oblique;
}

.impersonating_alert,
.cc_pharmacy_alert {
  width: 100%;
  background: #e3c343;
  text-align: center;
  margin-bottom: 0;
  font-size: 1em;
}

* {
  @apply font-montserrat;
}

@layer components {
  .transparent-input {
    @apply relative w-full text-gray-400 focus-within:text-gray-600;
    &__img-container {
      @apply absolute inset-y-0 flex items-center;
      img {
        @apply flex-shrink-0 h-5 w-5;
      }
    }

    select,
    input {
      @apply bg-transparent border-b-2 border-t-0 border-l-0 border-r-0 border-black text-white placeholder-white placeholder-opacity-90 pl-12 w-full h-full;
    }

    select {
      @apply capitalize;
    }
  }

  .registration-input,
  #id-verification-form {
    @apply relative w-full text-black focus-within:text-black;
    &__img-container {
      @apply absolute inset-y-0 flex items-center;
      img {
        @apply flex-shrink-0 h-5 w-5;
      }
    }

    select,
    input {
      @apply border-2 border-[#d9d9d9] text-black placeholder-black placeholder-opacity-90 px-5 py-4 min-w-full min-h-full shadow-none rounded-lg font-bold focus:border-black focus:ring-0;
    }

    select {
      @apply capitalize;
    }

    .field_with_errors {
      @apply w-full;
      input {
        @apply border-red-700;
      }
    }
  }

  .registration-header {
    @apply flex uppercase text-3xl font-termina font-bold;
  }

  .registration-subheader {
    @apply flex text-base font-gotham font-bold;
  }

  .registration-text {
    @apply flex text-sm font-gotham font-light;
  }

  .registration-submit-text {
    @apply flex uppercase text-base font-montserrat font-medium text-gray-900 tracking-wider;
  }

  .agreement {
    .field_with_errors {
      display: contents;
    }

    a {
      font-family: 'gotham-medium', Sans-Serif;
      @apply no-underline text-blue-agreement text-sm;
      &:hover {
        @apply text-blue-400;
      }
    }
  }

  .btn.gold {
    @apply block mx-auto px-5 py-2.5 text-xl uppercase font-medium rounded-full text-white bg-rugietgold hover:opacity-90;

    &:disabled {
      @apply opacity-60 cursor-not-allowed;
      &:hover {
        @apply opacity-60 cursor-not-allowed;
      }
    }
  }

  #error_explanation {
    @apply rounded-md bg-red-50 p-4 font-medium text-red-800 mb-10;
    ul {
      @apply list-disc pl-5 space-y-1;
    }
  }
}

#order-summary {
  .questionnaire-container {
    margin-top: 50px;
    @apply pb-0;
  }

  #cart-container {
    #dosage-errors,
    #quantity-errors {
      @apply text-red-600 text-sm absolute left-0 bottom-0;
    }
  }
}

.btn {
  width: 100%;
  @apply bg-link-active text-[14px] text-white h-[52px] tracking-wider hover:bg-opacity-90 hover:text-white;
}

.label {
  font-family: 'gotham-bold', 'sans-serif';
  @apply font-bold text-xs uppercase;
  &.required:after {
    content: ' *';
    color: red;
  }
}

.input {
  border: 1px solid #000000;
  border-radius: 15px;
  @apply py-4 bg-transparent block w-full box-border bg-[#FFFFFF] cursor-pointer;
}

.form-subheading {
  @apply text-lg font-bold leading-6 tracking-normal text-left;
}

.qa-tooltip {
  @apply absolute top-[50%] translate-y-[-50%] -right-4 w-8 h-8 bg-white rounded-full hover:cursor-pointer;
}

// body.wait,
// body.wait * {
//   cursor: wait !important;
// }

.spinner {
  background-image: url('../application/shared/images/spinner.gif');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 50px;
  position: fixed;
  height: 100vh;
  min-height: max-content;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
}

.radio-lg {
  width: 32px;
  height: 32px;
  margin: 0 auto;
  &:focus,
  &:active,
  &:checked {
    background-color: #0d0d0d !important;
    --tw-ring-color: #0d0d0d !important;
  }
}

.review-address-recommendation-frame:has(input:checked) {
  border-color: #767676;
  border-width: 3px;
}

.vendor-scripts {
  a,
  img {
    width: 86px;
  }

  @media (min-width: 768px) {
    a,
    img {
      width: 46px;
    }
  }

  @media (min-width: 1024px) {
    a,
    img {
      width: 86px;
    }
  }
}
