.dose-card {
  box-shadow: 0px -3px 4px 0px #6A6A6A40, 0px 3px 4px 0px #00000040;
  cursor: pointer;
  @apply max-w-md mb-8 lg:mb-4 rounded flex flex-col mx-auto bg-white;

  &.radio-button {
    @apply text-rugietmustard;
  }

  &__title {
    @apply rounded-t text-rugietmustard bg-black uppercase py-3 font-extrabold;
  }

  &__footer {
    @apply mx-4 my-3 font-bold underline;
  }

  &__lowest-price {
    font-size: 22px;
    font-weight: 700;
    vertical-align: middle;
    position: relative;
    margin: 0 0 0 10px;

    &:before {
      content: "$";
      font-size: 12px;
      position: absolute;
      top: -4px;
      left: -8px;
    }
  }

  &__price-per-dose {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 16px;
  }
}
