@import 'stylesheets/components/store/questionnaire/dose_card.scss';
@import 'stylesheets/components/store/questionnaire/frequency_card.scss';

#questionnaire {
  .heading {
    font-size: 35px;
    line-height: initial;
    text-align: start;
    letter-spacing: -0.02em;
    @apply w-full font-termina mb-6 lg:mb-14 font-bold;
    &.sm {
      font-size: 20px;
    }
    &.lg {
      font-size: 32px;
    }
    @media (max-width: 768px) {
      font-size: 25px;
    }
  }

  .questionnaire-content {
    select,
    input:not([type='checkbox']) {
      @apply bg-transparent border-2 border-[#d9d9d9] text-black placeholder-black placeholder-opacity-90 w-full h-full px-5 py-4 min-w-full shadow-none rounded-lg focus:border-black font-bold;
    }
    input[type='checkbox'] {
      @apply w-4 h-4 border-2 border-black shadow-none;
    }
  }

  &.user_verifications .questionnaire-container {
    @apply md:w-[650px] md:min-w-[650px];
  }

  .right-arrow-default {
    &:focus {
      background-image: url('../../assets/images/survey/right-arrow-hover.svg');
    }
  }

  .right-arrow-active {
    &:focus {
      background-image: url('../../assets/images/survey/right-arrow-hover.svg');
    }
  }

  .left-arrow-default {
    &:hover {
      background-image: url('../../assets/images/survey/left-arrow-hover.svg');
    }
  }

  // Desktop arrows
  .left-arrow-default {
    background-image: url('../../assets/images/survey/left-arrow-active.svg');
    height: 94px;
    width: 94px;
  }

  .right-arrow-default {
    background-image: url('../../assets/images/survey/right-arrow-inactive.svg');
    height: 94px;
    width: 94px;

    input:disabled.right-arrow-default:hover {
      background-image: url('../../assets/images/survey/right-arrow-inactive.svg') !important;
      opacity: 1;
    }
  }

  .arrows {
    display: flex;
    justify-content: center;
    gap: 50px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    @apply mt-10;
  }

  .arrow {
    display: block;
    background-color: #EAEAEA;
    width: 94px;
    height: 94px;

    border-radius: 50%;
  }

  .field_with_errors {
    input,
    textarea,
    select {
      @apply border-red-700 focus:border-red-700 w-full;
    }
  }
}
