.checkout-progress {
  background: #ffffff;
  box-shadow: inset 1px 1px 10px #aaaaaa;
  @apply w-full;
  &__bar {
    @apply border h-[15px] relative bg-rugietmustard;
    box-shadow: inset 0px 4px 4px rgba(255, 255, 255, 0.5);
    border-radius: 0px 10px 10px 0px;
  }
}
